@import "@npoed/frontend-theme/dist/assets/scss/variables";

.page-layout {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 100px);
  margin-top: 100px;

  &__content {
    flex: 1 0 100%;
  }

  .header {
    box-sizing: border-box;
  }
}
