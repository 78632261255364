@import "@npoed/frontend-theme/dist/assets/scss/variables";

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;

  .auth-wrapper {
    border: 1px solid $border-color-light;
    border-radius: 10px;
    padding: 20px;
    max-width: 500px;
    box-sizing: border-box;

    a {
      color: $link-color;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        color: $hover-link-color;
      }
    }

    .auth-section {
      padding: 15px 0;

      &__error-message {
        font-size: 14px;
        color: red;
      }

      &__tabs {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        font-size: 18px;
        padding-left: 15px;
        padding-right: 15px;
      }

      &__tab {
        &_inactive:hover {
          text-decoration: underline;
        }
      }

      &__labeled-input {
        input {
          margin-bottom: 25px;
        }
      }

      .rel_title {
        border-top: 1px solid $border-color-light;
        text-align: center;
        margin-top: 6px;

        &__label {
          position: relative;
          top: -11px;
          padding: 0 20px;
          background: white;
          font-size: 18px;
        }
      }
    }
  }
}
