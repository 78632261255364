.social-form {
  $gap: 8px;
  $border: 1px;

  max-width: 24rem;
  display: flex;
  flex-wrap: wrap;
  gap: $gap;
  list-style-type: none;
  align-content: stretch;
  padding: 0;
  margin: 15px auto;

  &__item {
    font-size: 12px;
    border: 1px solid #c8c8c8;
    border-radius: 2px;
    flex: 1 1 31%;
    min-width: 0;

    @media (max-width: 450px) {
      flex: 0 1 48%;
    }

    @media (max-width: 326px) {
      flex: 0 0 100%;
    }

    &:hover {
      background-color: #f5f5f5;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    text-decoration: none !important;
    color: #333 !important;
  }

  &__img {
    width: 30px;
    height: 30px;
    background-color: #f5f5f5;
    border-right: 1px solid #c8c8c8;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;

    > svg, > img {
      height: 15px;
      width: 15px;
      color: white;
    }
  }

  &__label {
    margin: 0 10px;
  }
}
