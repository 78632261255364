@import "@npoed/frontend-theme/dist/assets/scss/variables";

.registration-page-content {
  .secondary-input-variant {
    input {
      color: $secondary-font-color;
      border-radius: 4px;

      &:focus {
        outline: none;
      }
    }
  }

  .auth-section {
    &__labeled-select {
      margin-bottom: 15px;
    }

    &__labeled-checkbox {
      display: block;
      margin-bottom: 10px;
    }

    .select-course {
      font-size: 14px;

      select {
        display: block;
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: $secondary-font-color;
        background-color: white;
        background-image: none;
        border: 1px solid $border-color-light;
        border-radius: 4px;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);

        &:focus {
          outline: none;
          border-color: #66afe9;
        }
      }
    }

    .modal-footer {
      margin-top: 20px;

      &__paragraph {
        font-size: 14px;
      }

      &__link:hover {
        text-decoration: underline;
      }
    }
  }

  .register-lang-block {
    display: flex;
    justify-content: center;
  }
}
