@import "@npoed/frontend-theme/dist/assets/scss/variables";


.error-layout main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .error-type {
    font-size: 5rem;
    font-weight: 800;
    color: $control-color;
  }

  .error-summary {
    font-size: 1.5rem;
  }
}
