@import "@npoed/frontend-theme/dist/assets/scss/variables";
@import "@npoed/frontend-theme/dist/assets/scss/fonts";

.btn-submit  {
  width: 100%;
  font-size: 16px;
  font-family: "PFBeauSansPro", sans-serif;
  font-weight: $fw-semi-bold;
  padding: 6px 12px;
  line-height: 27px;
  text-align: center;
  color: #fff;
  text-decoration: none;
  background-color: $btn-submit-color;
  touch-action: manipulation;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;
  white-space: nowrap;
  user-select: none;

  &:focus, &:active {
    background-color: $active-btn-submit-color;
  }
}
