@import "@npoed/frontend-theme/dist/assets/scss/variables";

.login-page-content {
  .reset-password-link {
    position: relative;
    top: -20px;
    font-size: 12px;
  }

  .login-lang-block {
    display: flex;
    justify-content: center;
  }

  .to_registration-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;

    &__label {
      font-size: 18px;
      margin-top: 30px;
    }

    &__link {
      color: $btn-submit-color;
      font-weight: $fw-semi-bold;
      margin-top: 25px;
      text-decoration: none;

      &:focus, &:active {
        color: $active-btn-submit-color;
      }
    }
  }
}
