@import "@npoed/frontend-theme/dist/assets/scss/variables";

.select-container {
  color: $secondary-font-color;
  position: relative;

  &__control {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    font-size: 14px;
    box-sizing: border-box;
    padding: 6px;
    min-height: 34px;
    line-height: 1.42857143;
    border: 1px solid $border-color-light;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);

    &--expanded {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
      border-color: #66afe9;
    }
  }

  &__input {
    position: absolute;
    background: white;
    width: 100%;

    border: 1px solid $border-color-light;
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    &--expanded {
        box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
        border-color: $select-input-border-color;
    }

    .select-option {
      line-height: 1.42857143;
      padding: 5px;
      font-size: 14px;

      &:hover {
        color: white;
        background: $hover-select-input-bg-color;
      }
    }

    .select-search-input {
      border-radius: 4px;

      &:focus {
        outline: none;
      }

      &__wrapper {
        padding: 5px;
      }
    }
  }
}
