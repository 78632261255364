@import "@npoed/frontend-theme/dist/assets/scss/variables";


.login-expired-layout main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .login-expired-title {
    font-size: 2.2rem;
    margin-bottom: 0.5rem;
  }

  .login-expired-assumption {
    font-size: 1.1rem;
  }

  a {
    text-decoration: none;
    color: $control-color;

    &:hover {
      color: $hover-control-color;
    }
  }
}
