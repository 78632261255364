.labeled-form-input {
  font-size: 14px;
  width: 100%;
  font-weight: normal;

  input {
    margin-top: 2px;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  }

  .error-message {
    font-size: 14px;
    color: red;
  }
}
