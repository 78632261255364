.reset-password-page-content {
  p {
    font-size: 14px;
  }

  .auth-section {
    &__labeled-input {
      margin-bottom: 25px;
    }
  }
}