@import "@npoed/frontend-theme/dist/assets/scss/variables";

.form-input {
  color: $primary-font-color;
  width: 100%;
  font-size: 14px;
  box-sizing: border-box;
  padding: 5px;
  border: 1px solid $border-color-light;
  line-height: 1.42857143;
}
